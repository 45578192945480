import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Heading,
  StackDivider,
  Center,
  VStack,
  HStack,
  Spacer,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import { MdAlternateEmail } from "react-icons/md";
import { GoBackButton } from "../utils/Buttons";
import { UserAPI } from "../../api/UserAPI";
import GenericEditableInput from "../utils/editable/Generic";
import EditableSwitch from "../utils/editable/EditableSwitch";
import { Person } from "../utils/editable/Person";
import GroupSelector from "../utils/editable/GroupSelector";

const UserInfo = () => {
  const params = useParams();
  const userID = params.userID;
  const [submittingData, setSubmittingData] = React.useState(false);
  const [getUserInfo, userInfo, userInfoLoaded] = UserAPI.getUserByID();
  const updateUserByID = UserAPI.updateUserByID();
  const sendSignupTokenEmail = UserAPI.resetPasswordUser(userID);

  useEffect(() => {
    getUserInfo(userID);
  }, [userID]);

  return (
    <VStack spacing={4} padding={4} margin={10}>
      <HStack w="100%">
        <GoBackButton w="110px" />
        <Spacer />
        <VStack pr="110px">
          <Heading fontSize={"2xl"} textAlign={"left"}>
            {(userInfo?.role?.name || "User") + ": " + (userInfo?.name || "")}
          </Heading>
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
        </VStack>
        <Spacer />
      </HStack>
      <VStack bg="white" rounded="md" w="100%">
        <HStack mb={4} w="70%">
          <Center w="100%">
            {userInfoLoaded ? (
              <UserFields
                userInfo={userInfo}
                getUserInfo={getUserInfo}
                updateUserByID={updateUserByID}
                submittingData={submittingData}
                setSubmittingData={setSubmittingData}
                sendSignupTokenEmail={sendSignupTokenEmail}
              />
            ) : (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            )}
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

function UserFields({
  userInfo,
  updateUserByID,
  getUserInfo,
  submittingData,
  setSubmittingData,
  sendSignupTokenEmail,
}) {
  return (
    <VStack w="100%" bg="white" borderRadius="lg" spacing={10} mt={10}>
      <GenericEditableInput
        label="Name"
        icon={<FiUser color="gray.800" />}
        value={userInfo?.name}
        onUpdate={(new_name) => {
          updateUserByID(userInfo.id, {
            name: new_name,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />
      <GenericEditableInput
        label="Email"
        icon={<MdAlternateEmail color="gray.800" />}
        value={userInfo?.email}
        onUpdate={(new_email) => {
          updateUserByID(userInfo.id, {
            email: new_email,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />
      <Person
        label="Personal info"
        account_id={userInfo?.account_id}
        person_id={userInfo?.person_id}
        onCreatePerson={(new_person_id) => {
          updateUserByID(userInfo.id, {
            person_id: new_person_id,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />
      <GroupSelector label={"User groups"} userInfo={userInfo} />
      <EditableSwitch
        label="User Enabled"
        value={userInfo?.enabled}
        onUpdate={(new_enabled) => {
          updateUserByID(userInfo.id, {
            enabled: new_enabled,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />

      <Button
        isLoading={submittingData}
        loadingText="Sending email"
        colorScheme="red"
        disabled={submittingData}
        onClick={() => {
          setSubmittingData(true);
          sendSignupTokenEmail().then((response) => {
            setSubmittingData(false);
          });
        }}
      >
        Reset password
      </Button>
    </VStack>
  );
}

export default UserInfo;
