import { useAxiosPost } from "../hooks/UseAxios";
import {
  popupError,
  popupSuccess,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

export class ProductAPI {
  static postProduct = () => {
    const popup = GetPopup();
    return useAxiosPost(
      C.CREATE_PRODUCT_URL,
      () => popupSuccess(popup, "Product created successfully"),
      (detail) => popupError(popup, "Cannot create product", detail)
    );
  };

  static productsByAccount = () => {
    return APIUtils.dataByAccountByRange(
      C.GET_PRODUCTS_BY_ACCOUNT_URL,
      "Unable to load products"
    );
  };

  static getProductByID = () => {
    return APIUtils.getDataByID(
      C.GET_PRODUCT_BY_ID_URL,
      "Unable to get product information"
    );
  };

  static updateProductByID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_PRODUCT_BY_ID_URL,
      "Product information updated",
      "Unable to update product information"
    );
  };
}
