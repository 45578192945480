import React, { useContext } from "react";
import {
  Heading,
  VStack,
  Stack,
  StackDivider,
  UnorderedList,
  HStack,
  Center,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { FiBook } from "react-icons/fi";
import { GrUserAdmin } from "react-icons/gr";
import { CurrentUserPerson } from "../utils/editable/Person";
import { UserContext } from "../../context/UserContext";
import { AccountContext } from "../../context/AccountContext";

const PersonalInfo = () => {
  const user = useContext(UserContext);
  const workingAccount = useContext(AccountContext);

  React.useEffect(() => {}, []);
  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
      w="100%"
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          User: {user.name}
        </Heading>
      </Stack>
      <Center mt={8}>
        <VStack w="70%" spacing={5}>
          <HStack w="100%">
            <HStack w="100%">
              <GrUserAdmin />
              <Text size="sm" fontWeight="bold">
                Role:
              </Text>
              <Text>{user.role}</Text>
            </HStack>
            <HStack w="100%">
              <FiBook />
              <Text size="sm" fontWeight="bold">
                Account:
              </Text>
              <Text>{workingAccount.name}</Text>
            </HStack>
          </HStack>
          <CurrentUserPerson />
        </VStack>
      </Center>
    </VStack>
  );
};

export default PersonalInfo;
