import { useRef, useState, useContext } from "react";
import axios from "../api/axios";
import {
  popupInfo,
  popupSuccess,
  popupError,
  GetPopup,
} from "../components/utils/StatusPopup";

import { AuthContext } from "../context/AuthContext";

export class APIUtils {
  static dataByAccountByRange = (baseURL, errorMessage) => {
    const popup = GetPopup();
    const auth = useContext(AuthContext);
    const [data, setData] = useState(null);
    const [dataAmount, setDataAmount] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [error, setError] = useState("");
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };
    const requestData = async (workingAccountID, skip = 0, limit = 100) => {
      const url = `${baseURL}${workingAccountID}?skip=${skip}&limit=${limit}`;

      try {
        const response = await axios.get(url, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          signal: controllerRef.current.signal,
        });
        setDataLoaded(true);
        setData(response?.data?.data);
        setDataAmount(response?.data?.count);
      } catch (err) {
        console.log("ERROR", err?.response);
        setError(err?.response);
        if (errorMessage) {
          popupInfo(popup, errorMessage);
        }
      }
    };

    return [requestData, dataAmount, data, dataLoaded, error, cancel];
  };

  static dataByRange = (baseURL, errorMessage) => {
    const popup = GetPopup();
    const auth = useContext(AuthContext);
    const [data, setData] = useState(null);
    const [dataAmount, setDataAmount] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [error, setError] = useState("");
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };
    const requestData = async (skip = 0, limit = 100) => {
      if (auth.token && auth.token !== "null") {
        const url = `${baseURL}?skip=${skip}&limit=${limit}`;
        try {
          const response = await axios.get(url, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + auth.token,
            },
            signal: controllerRef.current.signal,
          });
          setDataLoaded(true);
          setData(response?.data?.data);
          setDataAmount(response?.data?.count);
        } catch (err) {
          console.log("ERROR", err?.response);
          setError(err?.response);
          if (errorMessage) {
            popupInfo(popup, errorMessage);
          }
        }
      }
    };

    return [requestData, dataAmount, data, dataLoaded, error, cancel];
  };

  static getDataByID = (getByID_URL, errorMessage) => {
    const popup = GetPopup();
    const auth = useContext(AuthContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };

    const getData = async (data_id) => {
      let response = null;
      try {
        response = await axios.get(getByID_URL(data_id), {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          signal: controllerRef.current.signal,
        });
        setData(response?.data);
        setLoaded(true);
      } catch (err) {
        if (errorMessage) {
          popupInfo(popup, errorMessage);
        }
        setError(err?.response);
        console.log("ERROR", err?.response);
      }

      return response;
    };

    return [getData, data, loaded, error, cancel];
  };

  static updateDataByID = (updateDataByIDURL, successMessage, erroMessage) => {
    const auth = useContext(AuthContext);
    const popup = GetPopup();
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };
    const patchMethod = async (data_id, data) => {
      let response = null;
      try {
        response = await axios.patch(updateDataByIDURL(data_id), data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          signal: controllerRef.current.signal,
        });
        if (successMessage) {
          popupSuccess(popup, successMessage);
        }
      } catch (err) {
        console.log("ERROR: " + JSON.stringify(err?.response));
        if (erroMessage) {
          popupError(popup, erroMessage, err?.response?.data?.detail);
        }
      }
      return response;
    };

    return patchMethod;
  };

  static postDataByID = (postByID_URL, errorMessage) => {
    const popup = GetPopup();
    const auth = useContext(AuthContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };

    const postData = async (data_id, data) => {
      let response = null;
      try {
        response = await axios.post(postByID_URL(data_id), data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          signal: controllerRef.current.signal,
        });
        setData(response?.data);
        setLoaded(true);
      } catch (err) {
        if (errorMessage) {
          popupInfo(popup, errorMessage);
        }
        setError(err?.response);
        console.log("ERROR", err?.response);
      }

      return response;
    };

    return [postData, data, loaded, error, cancel];
  };
}
