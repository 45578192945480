import { useAxiosPost } from "../hooks/UseAxios";
import {
  popupError,
  popupSuccess,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

export class SoftwareAPI {
  static getSoftwares = () => {
    return APIUtils.dataByRange(
      C.GET_SOFTWARES_URL,
      "Unable to load softwares"
    );
  };

  static postSoftware = () => {
    const popup = GetPopup();
    const onSuccess = () =>
      popupSuccess(popup, "Software created successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot create software", detail);
    return useAxiosPost(C.CREATE_SOFTWARE_URL, onSuccess, onError);
  };

  static getSoftwareByID = () => {
    return APIUtils.getDataByID(
      C.GET_SOFTWARE_BY_ID_URL,
      "Unable to get software information"
    );
  };

  static updateSoftwareByID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_SOFTWARE_BY_ID_URL,
      "Software information updated",
      "Unable to update software information"
    );
  };
}
