import { useToast } from "@chakra-ui/react";

const ERROR_DURATION = 4000;
const SUCCESS_DURATION = 2000;
const INFO_DURATION = 6000;
const ERROR_POSITION = "bottom-center";
const SUCCESS_POSITION = "bottom-center";
const INFO_POSITION = "bottom-center";
const CLOSABLE = true;

export function GetPopup() {
  return useToast();
}

export function popupError(popup, message, detail = null) {
  let description = "";
  if (Array.isArray(detail)) {
    detail.forEach((elem) => (description += elem.msg + "\n"));
  } else if (typeof detail === "string" || detail instanceof String) {
    description = detail;
  }
  popup({
    title: message,
    description: description,
    status: "error",
    duration: ERROR_DURATION,
    isClosable: CLOSABLE,
    position: ERROR_POSITION,
  });
}

export function popupSuccess(popup, message, detail = null) {
  let description = "";
  if (Array.isArray(detail)) {
    detail.forEach((elem) => (description += elem.msg + "\n"));
  } else if (detail instanceof String) {
    description = detail;
  }
  popup({
    title: message,
    description: description,
    status: "success",
    duration: SUCCESS_DURATION,
    isClosable: CLOSABLE,
    position: SUCCESS_POSITION,
  });
}

export function popupInfo(popup, message, detail = null) {
  let description = "";
  if (Array.isArray(detail)) {
    detail.forEach((elem) => (description += elem.msg + "\n"));
  } else if (detail instanceof String) {
    description = detail;
  }
  popup({
    title: message,
    description: description,
    status: "info",
    duration: INFO_DURATION,
    isClosable: CLOSABLE,
    position: INFO_POSITION,
  });
}
