import * as React from "react";
import {
  ChakraProvider,
  Stack,
  Spinner,
  VStack,
  Spacer,
} from "@chakra-ui/react";
import { useContext } from "react";

import Login from "./components/login/Login";
import AccountWithAdminForm from "./components/account/AccountWithAdminForm";
import AccountInfo from "./components/account/AccountInfo";
import AccountsTable from "./components/account/AccountsTable";
import GroupForm from "./components/groups/GroupForm";
import GroupsTable from "./components/groups/GroupsTable";
import GroupInfo from "./components/groups/GroupInfo";
import UserWithGroupForm from "./components/users/UserWithGroupsForm";
import UserInfo from "./components/users/UserInfo";
import UsersTable from "./components/users/UsersTable";
import SoftwareForm from "./components/softwares/SoftwareForm";
import SoftwaresTable from "./components/softwares/SoftwaresTable";
import SoftwareInfo from "./components/softwares/SoftwareInfo";
import LicenseForm from "./components/licenses/LicenseForm";
import LicensesTable from "./components/licenses/LicensesTable";
import LicenseInfo from "./components/licenses/LicenseInfo";
import SessionsTable from "./components/sessions/SessionsTable";
import MySessions from "./components/sessions/MySessions";
import ProductForm from "./components/products/ProductForm";
import ProductsTable from "./components/products/ProductsTable";
import ProductInfo from "./components/products/ProductInfo";
import ProductionsTable from "./components/productions/ProductionsTable";
import NotFound from "./components/NotFound";
import Unauthorized from "./components/Unauthorized";
import SetPassword from "./components/SetPassword";
import PersonalInfo from "./components/profile/PersonalInfo";
import PasswordChange from "./components/profile/PasswordChange";
import { PathAuth, ROLES } from "./components/Auth";

import { AuthContext } from "./context/AuthContext";
import { UserContext } from "./context/UserContext";

import Sidebar from "./components/utils/SideBar.jsx";
import Headerbar from "./components/utils/HeaderBar.jsx";
import Content from "./components/utils/Content";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { OverlayContainer } from "@react-aria/overlays";

const App = () => {
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);

  return (
    <ChakraProvider>
      <OverlayContainer>
        <BrowserRouter>
          {!auth.token ? (
            /* unlogged routes */
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="setpassword/*" element={<SetPassword />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : user.role ? (
            <Stack direction="column" spacing="2vh">
              <Headerbar />
              <Stack direction="row" spacing="1vw">
                <Sidebar />
                <Content>
                  <Routes>
                    {/* unprotected routes */}
                    <Route path="/" element={<PersonalInfo />} />
                    <Route path="/profile/info" element={<PersonalInfo />} />
                    <Route
                      path="/profile/password"
                      element={<PasswordChange />}
                    />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="*" element={<NotFound />} />
                    {/* protected routes */}
                    <Route
                      path="accounts"
                      element={<PathAuth allowedRoles={[ROLES.SUPER_USER]} />}
                    >
                      <Route path="new" element={<AccountWithAdminForm />} />
                      <Route path="show" element={<AccountsTable />} />
                      <Route path=":accountName" element={<AccountInfo />} />
                    </Route>
                    <Route
                      path="groups"
                      element={
                        <PathAuth
                          allowedRoles={[ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]}
                        />
                      }
                    >
                      <Route path="new" element={<GroupForm />} />
                      <Route path="show" element={<GroupsTable />} />
                      <Route path=":groupID" element={<GroupInfo />} />
                    </Route>
                    <Route
                      path="users"
                      element={
                        <PathAuth
                          allowedRoles={[ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]}
                        />
                      }
                    >
                      <Route path="new" element={<UserWithGroupForm />} />
                      <Route path="show" element={<UsersTable />} />
                      <Route path=":userID" element={<UserInfo />} />
                    </Route>
                    <Route
                      path="softwares"
                      element={
                        <PathAuth
                          allowedRoles={[ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]}
                        />
                      }
                    >
                      <Route
                        element={<PathAuth allowedRoles={[ROLES.SUPER_USER]} />}
                      >
                        <Route path="new" element={<SoftwareForm />} />
                      </Route>
                      <Route
                        element={
                          <PathAuth
                            allowedRoles={[
                              ROLES.SUPER_USER,
                              ROLES.ACCOUNT_ADMIN,
                            ]}
                          />
                        }
                      >
                        <Route path="show" element={<SoftwaresTable />} />
                        <Route path=":softwareID" element={<SoftwareInfo />} />
                      </Route>
                    </Route>
                    <Route
                      path="licenses"
                      element={
                        <PathAuth
                          allowedRoles={[
                            ROLES.SUPER_USER,
                            ROLES.VISIONX_ADMIN,
                            ROLES.ACCOUNT_ADMIN,
                          ]}
                        />
                      }
                    >
                      <Route
                        element={
                          <PathAuth
                            allowedRoles={[
                              ROLES.SUPER_USER,
                              ROLES.VISIONX_ADMIN,
                            ]}
                          />
                        }
                      >
                        <Route path="new" element={<LicenseForm />} />
                      </Route>
                      <Route
                        element={
                          <PathAuth
                            allowedRoles={[
                              ROLES.SUPER_USER,
                              ROLES.VISIONX_ADMIN,
                              ROLES.ACCOUNT_ADMIN,
                            ]}
                          />
                        }
                      >
                        <Route path="show" element={<LicensesTable />} />
                        <Route path=":licenseID" element={<LicenseInfo />} />
                      </Route>
                    </Route>
                    <Route
                      path="sessions"
                      element={
                        <PathAuth
                          allowedRoles={[ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]}
                        />
                      }
                    >
                      <Route index element={<SessionsTable />} />
                    </Route>
                    <Route
                      path="mysessions"
                      element={<PathAuth allowedRoles={[ROLES.USER]} />}
                    >
                      <Route index element={<MySessions />} />
                    </Route>
                    <Route
                      path="products"
                      element={
                        <PathAuth
                          allowedRoles={[ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]}
                        />
                      }
                    >
                      <Route path="new" element={<ProductForm />} />
                      <Route path="show" element={<ProductsTable />} />
                      <Route path=":productID" element={<ProductInfo />} />
                    </Route>
                    <Route
                      path="productions"
                      element={
                        <PathAuth
                          allowedRoles={[ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]}
                        />
                      }
                    >
                      <Route index element={<ProductionsTable />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Content>
              </Stack>
            </Stack>
          ) : (
            <VStack
              verticalAlign="center"
              alignItems="center"
              w="100vw"
              h="100vh"
            >
              <Spacer />
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
              <Spacer />
            </VStack>
          )}
        </BrowserRouter>
      </OverlayContainer>
    </ChakraProvider>
  );
};

export default App;
