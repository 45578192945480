import { useAxiosPost } from "../hooks/UseAxios";
import {
  popupError,
  popupSuccess,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

export class LicenseAPI {
  static licensesByAccount = () => {
    return APIUtils.dataByAccountByRange(
      C.GET_LICENSES_IN_ACCOUNT_URL,
      "Unable to load licenses"
    );
  };

  static postLicense = () => {
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "License created successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot create license", detail);
    return useAxiosPost(C.CREATE_LICENSE_URL, onSuccess, onError);
  };

  static updateLicenseByID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_LICENSE_BY_ID_URL,
      "License information updated",
      "Unable to update license information"
    );
  };

  static getLicenseByID = () => {
    return APIUtils.getDataByID(
      C.GET_LICENSE_BY_ID_URL,
      "Unable to get license information"
    );
  };
}
