import { useRef } from "react";
import { useDatePickerState } from "@react-stately/datepicker";
import { useDatePicker } from "@react-aria/datepicker";
import { Calendar } from "./Calendar";
import { DateField, StyledField, TimeField } from "./DateField";
import { Popover } from "../Popover";
import { CalendarIcon, NotAllowedIcon } from "@chakra-ui/icons";
import { useButton } from "@react-aria/button";
import {
  FormLabel,
  InputGroup,
  Box,
  InputRightElement,
  HStack,
  Button as ChakraButton,
} from "@chakra-ui/react";

export function DatePicker(props) {
  let state = useDatePickerState({
    ...props,
    shouldCloseOnSelect: false,
  });
  let ref = useRef();
  let {
    groupProps,
    labelProps,
    fieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(props, state, ref);

  return (
    <Box
      w="100%"
      position="relative"
      display="inline-flex"
      flexDirection="column"
      gap={3}
    >
      <HStack w="100%" align="center">
        <CalendarIcon color="gray.800" />

        <FormLabel {...labelProps} fontWeight="bold">
          {props.label}
        </FormLabel>
      </HStack>

      <InputGroup {...groupProps} ref={ref} width="100%" display="inline-flex">
        <StyledField pr="4.5rem" width="100%">
          <DateField {...fieldProps} />
          {state.validationState === "invalid" && (
            <NotAllowedIcon color="red.600" position="absolute" right="12" />
          )}
        </StyledField>
        <InputRightElement>
          <FieldButton {...buttonProps} isPressed={state.isOpen}>
            <CalendarIcon />
          </FieldButton>
        </InputRightElement>
      </InputGroup>
      {state.isOpen && (
        <Popover
          {...dialogProps}
          isOpen={state.isOpen}
          onClose={() => {
            state.setOpen(false);
            if (props.onClose) {
              props.onClose();
            }
          }}
        >
          <Calendar {...calendarProps} />

          {props.hideTimeField ? (
            <></>
          ) : (
            <TimeField
              label="Time"
              value={state.timeValue}
              onChange={state.setTimeValue}
            />
          )}
        </Popover>
      )}
    </Box>
  );
}

function FieldButton(props) {
  let ref = useRef();
  let { buttonProps } = useButton(props, ref);
  return (
    <ChakraButton {...buttonProps} ref={ref} size="sm" h="1.75rem" mr="2">
      {props.children}
    </ChakraButton>
  );
}
