import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Heading,
  StackDivider,
  Center,
  VStack,
  HStack,
  Spacer,
  Spinner,
} from "@chakra-ui/react";
import { FiCpu } from "react-icons/fi";
import { MdDescription, MdAssignment, MdFactCheck } from "react-icons/md";

import { GoBackButton } from "../utils/Buttons";
import { SoftwareAPI } from "../../api/SoftwareAPI";
import GenericEditableInput from "../utils/editable/Generic";

const SoftwareInfo = () => {
  const params = useParams();
  const softwareID = params.softwareID;
  const [getSoftwareInfo, softwareInfo, softwareInfoLoaded] =
    SoftwareAPI.getSoftwareByID();
  const updateSoftwareByID = SoftwareAPI.updateSoftwareByID();

  useEffect(() => {
    getSoftwareInfo(softwareID);
  }, [softwareID]);

  return (
    <VStack spacing={4} padding={4} margin={10}>
      <HStack w="100%">
        <GoBackButton w="110px" />
        <Spacer />
        <VStack pr="110px">
          <Heading fontSize={"2xl"} textAlign={"left"}>
            {"Software: " + (softwareInfo?.name || "")}
          </Heading>
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
        </VStack>
        <Spacer />
      </HStack>
      <VStack bg="white" rounded="md" w="100%">
        <HStack mb={4} w="70%">
          <Center w="100%">
            {softwareInfoLoaded ? (
              <SoftwareFields
                softwareInfo={softwareInfo}
                updateSoftwareByID={updateSoftwareByID}
                getSoftwareInfo={getSoftwareInfo}
              />
            ) : (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            )}
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

function SoftwareFields({ softwareInfo, updateSoftwareByID, getSoftwareInfo }) {
  return (
    <VStack w="100%" bg="white" borderRadius="lg" spacing={10} mt={10}>
      <GenericEditableInput
        label="Name"
        icon={<FiCpu color="gray.800" />}
        value={softwareInfo?.name}
        onUpdate={(new_name) => {
          updateSoftwareByID(softwareInfo.id, {
            name: new_name,
          }).then((response) => {
            // After updating we need to reload software info
            if (response?.status == 200) {
              getSoftwareInfo(softwareInfo.id);
            }
          });
        }}
      />
      <GenericEditableInput
        label="Version"
        icon={<MdFactCheck color="gray.800" />}
        value={softwareInfo?.version}
        onUpdate={(new_version) => {
          updateSoftwareByID(softwareInfo.id, {
            version: new_version,
          }).then((response) => {
            // After updating we need to reload software info
            if (response?.status == 200) {
              getSoftwareInfo(softwareInfo.id);
            }
          });
        }}
      />
      <GenericEditableInput
        label="Description"
        icon={<MdDescription color="gray.800" />}
        value={softwareInfo?.description}
        onUpdate={(new_description) => {
          updateSoftwareByID(softwareInfo.id, {
            description: new_description,
          }).then((response) => {
            // After updating we need to reload software info
            if (response?.status == 200) {
              getSoftwareInfo(softwareInfo.id);
            }
          });
        }}
      />
      <GenericEditableInput
        label="Install path"
        icon={<MdAssignment color="gray.800" />}
        value={softwareInfo?.install_path}
        onUpdate={(new_install_path) => {
          updateSoftwareByID(softwareInfo.id, {
            install_path: new_install_path,
          }).then((response) => {
            // After updating we need to reload software info
            if (response?.status == 200) {
              getSoftwareInfo(softwareInfo.id);
            }
          });
        }}
      />
    </VStack>
  );
}

export default SoftwareInfo;
