import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  chakra,
  Avatar,
  Heading,
  HStack,
  Box,
  Icon,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
} from "@chakra-ui/react";

import { FiLogOut } from "react-icons/fi";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { AuthLogout } from "../../context/AuthContext";
import { UserContext } from "../../context/UserContext";
import {
  AccountContext,
  AccountContextSelect,
} from "../../context/AccountContext";

import LightHomeLogo from "../utils/HomeLogo";

export default function Headerbar() {
  const user = useContext(UserContext);
  const workingAccount = useContext(AccountContext);
  const logout = AuthLogout();

  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    workingAccount.setId(null);
    navigate("/");
  };
  const heightSize = "60px";

  return (
    <chakra.header id="header">
      <HStack
        zIndex="dropdown"
        px="5"
        py="2"
        align="center"
        justify="space-between"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.5)"
        pos="sticky"
        flexDir="row"
        h={heightSize}
        bg="#2d3238"
      >
        <LightHomeLogo size="60px" />
        <Spacer />
        <HStack as="nav" align="center">
          <AccountContextSelect />
          <Box>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon color="white" />}
                _hover={{ background: "none" }}
                _expanded={{ background: "none" }}
                _focus={{ background: "none" }}
                bg="#2d3238"
              >
                <HStack alignItems="center">
                  <Avatar
                    size="sm"
                    src="https://avatars2.githubusercontent.com/u/37842853?v=4"
                  />
                  <Heading color="white" as="h3" size="md">
                    {user.name}
                  </Heading>
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    navigate("/profile/info");
                  }}
                >
                  Personal information
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    navigate("/profile/password");
                  }}
                >
                  Change password
                </MenuItem>

                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <Icon as={FiLogOut} fontSize="xl" color="red" />
                  <Text ml={1}> Log out </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </HStack>
    </chakra.header>
  );
}
