import React, { useMemo, useContext, useCallback } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Heading, Stack, StackDivider, VStack } from "@chakra-ui/react";
import FilterTable from "../utils/FilterTable";
import { AccountContext } from "../../context/AccountContext";
import { GoToEditPageButton } from "../utils/Buttons";
import { ProductAPI } from "../../api/ProductAPI";

const ProductsTable = () => {
  const workingAccount = useContext(AccountContext);
  const [getProductsByAccount, productsAmount, products] =
    ProductAPI.productsByAccount();

  const columns = useMemo(
    () => [
      { Header: "Name", accessor: "getName" },
      { Header: "Code", accessor: "getCode" },
      { Header: "Description", accessor: "getDescription" },
      { Header: "Enabled", accessor: "getEnabled" },
      { Header: "", accessor: "getEditButton", disableFilters: true },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (products !== null) {
      products.map((product) => {
        values.push({
          getName: (
            <ReachLink to={"/products/" + product.id}>{product.name}</ReachLink>
          ),
          getCode: product.code,
          getDescription: product.description,
          getEnabled: product.enabled ? "yes" : "no",
          getEditButton: <GoToEditPageButton to={"/products/" + product.id} />,
        });
      });
    }
    return values;
  }, [products]);

  const fetchData = useCallback(
    (pageSize, pageIndex) => {
      getProductsByAccount(workingAccount.id, pageSize * pageIndex, pageSize);
    },
    [workingAccount.id]
  );

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Products Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Products"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={productsAmount}
        resetTableDependencies={[workingAccount.id]}
      />
    </VStack>
  );
};

export default ProductsTable;
