import { useAxiosPost } from "../hooks/UseAxios";
import {
  popupError,
  popupSuccess,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

export class GroupAPI {
  static groupsByAccount = () => {
    return APIUtils.dataByAccountByRange(
      C.GET_GROUPS_IN_ACCOUNT_URL,
      "Unable to load groups"
    );
  };

  static getGroupByID = () => {
    return APIUtils.getDataByID(
      C.GET_GROUP_BY_ID_URL,
      "Unable to get group information"
    );
  };

  static updateGroupByID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_GROUP_BY_ID_URL,
      "Group updated successfully",
      "Unable to update group"
    );
  };

  static postGroup = () => {
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "Group created successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot create group", detail);
    return useAxiosPost(C.CREATE_GROUP_URL, onSuccess, onError);
  };
}
