import { useContext } from "react";
import axios from "./axios";
import { popupError, GetPopup } from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { AuthLogin } from "../context/AuthContext";
import { AccountContext } from "../context/AccountContext";

export class LoginAPI {
  static postLogin = () => {
    const workingAccount = useContext(AccountContext);
    const login = AuthLogin();
    const popup = GetPopup();

    const submitLogin = async (values) => {
      try {
        const loginResponse = await axios.post(
          C.LOGIN_URL,
          JSON.stringify(
            `grant_type=&username=${values["name"]}&password=${values["password"]}&scope=SUPER_USER&client_id=&client_secret=`
          ),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        );
        login(loginResponse?.data?.access_token);
        workingAccount.setId(null);
      } catch (err) {
        popupError(popup, "Cannot login", "Invalid credentials");
      }
    };

    return submitLogin;
  };
}
