import React, { useEffect, useContext } from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  StackDivider,
  Switch,
  VStack,
  HStack,
  Spacer,
  Center,
} from "@chakra-ui/react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import * as C from "../../config/Constants";
import { hasWhiteSpaces, isValidEmail } from "../utils/Utils";
import { UserAPI } from "../../api/UserAPI";
import { GroupAPI } from "../../api/GroupAPI";
import { AccountContext } from "../../context/AccountContext";

const UserWithGroupForm = () => {
  const [submittingData, setSubmittingData] = React.useState(false);
  const workingAccount = useContext(AccountContext);
  const [getGroupsInAccount, , groupsInAccount, groupsInAccountLoaded] =
    GroupAPI.groupsByAccount();
  /*TODO The setUserEnabled state could be handled with Fields props like in LicenseForm. This should be changed when API is refactored*/
  const [setUserEnabled, setSendEmail, submitUser] = UserAPI.postUsers();

  useEffect(() => {
    getGroupsInAccount(workingAccount.id);
  }, [workingAccount.id]);

  return (
    <VStack spacing={4} padding={4}>
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"left"}>
          Create regular user
        </Heading>
      </Stack>

      <Box bg="white" w="50vh">
        <HStack mb={4} w="100%">
          <Spacer />
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
          <Spacer />
        </HStack>
        <Formik
          initialValues={{
            userName: "",
            userEmail: "",
            userEnabled: true,
            sendEmail: true,
            tokenLifetime: C.TOKEN_LIFETIME,
          }}
          onSubmit={async (values, { resetForm }) => {
            submitUser(values).then((response) => {
              setSubmittingData(false);
              if (response?.status == 200) {
                resetForm();
              }
            });
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={errors.userName && touched.userName}
                >
                  <FormLabel htmlFor="name">New user name</FormLabel>
                  <Field
                    as={Input}
                    id="userName"
                    name="userName"
                    type="userName"
                    variant="filled"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "User name is required";
                      };
                      if (hasWhiteSpaces(value)){
                        error = "User name can't have whitespaces"
                      };
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.userName}</FormErrorMessage>
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="enabled" mb="0">
                    User enabled
                  </FormLabel>
                  <Switch
                    id="userEnabled"
                    name="userEnabled"
                    size="md"
                    defaultChecked
                    onChange={(e) => {
                      setUserEnabled(e.target.checked);
                    }}
                  />
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={errors.userEmail && touched.userEmail}
                >
                  <FormLabel htmlFor="name">New user email</FormLabel>
                  <Field
                    as={Input}
                    id="userEmail"
                    name="userEmail"
                    type="userEmail"
                    variant="filled"
                    validate={(value) => {
                      if (!value) {
                        return "User email is required";
                      }
                      if (!isValidEmail(value)) {
                        return "User email is not valid";
                      }
                      return null;
                    }}
                  />
                  <FormErrorMessage>{errors.userEmail}</FormErrorMessage>
                </FormControl>
                <FormControl hidden={true} display="flex" alignItems="center">
                  <FormLabel htmlFor="enabled" mb="0">
                    Send email to set password
                  </FormLabel>
                  <Switch
                    id="sendEmail"
                    name="sendEmail"
                    size="md"
                    defaultChecked
                    onChange={(e) => {
                      setSendEmail(e.target.checked);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="name">Token duration (hours)</FormLabel>
                  <Field name="tokenLifetime">
                    {({ field, form }) => (
                      <NumberInput
                        defaultValue={1}
                        min={0}
                        id="tokenLifetime"
                        {...field}
                        onChange={(val) => form.setFieldValue(field.name, val)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  </Field>
                </FormControl>
                {groupsInAccount &&
                groupsInAccount.length &&
                groupsInAccountLoaded ? (
                  <FormControl>
                    <FormLabel htmlFor="groups">Select groups</FormLabel>
                    <Field name="userGroups">
                      {({ field, form }) => (
                        <CheckboxGroup
                          defaultValue={[]}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                        >
                          {groupsInAccount.map((group) => (
                            <Checkbox
                              id={group.id}
                              key={group.id}
                              value={String(group.id)}
                              margin="10px"
                            >
                              {" "}
                              {group.name}{" "}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      )}
                    </Field>
                  </FormControl>
                ) : (
                  <Stack></Stack>
                )}

                <Center w="100%">
                  <Button
                    isLoading={submittingData}
                    loadingText="Submitting"
                    type="submit"
                    colorScheme="purple"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      submittingData
                    }
                    onClick={(value) => {
                      handleSubmit(value);
                      setSubmittingData(true);
                    }}
                  >
                    Create user
                  </Button>
                </Center>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default UserWithGroupForm;
