import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Heading,
  StackDivider,
  Center,
  VStack,
  HStack,
  Spacer,
  Spinner,
} from "@chakra-ui/react";
import { BiGroup } from "react-icons/bi";

import { GoBackButton } from "../utils/Buttons";
import { GroupAPI } from "../../api/GroupAPI";

import GenericEditableInput from "../utils/editable/Generic";
import EditableSwitch from "../utils/editable/EditableSwitch";
import { GroupAddress } from "../utils/editable/Address";
import { Person } from "../utils/editable/Person";

const GroupInfo = () => {
  const params = useParams();
  const groupID = params.groupID;
  const [getGroupInfo, groupInfo, groupInfoLoaded] = GroupAPI.getGroupByID();
  const updateGroupByID = GroupAPI.updateGroupByID();

  useEffect(() => {
    getGroupInfo(groupID);
  }, []);

  return (
    <VStack spacing={4} padding={4} margin={10}>
      <HStack w="100%">
        <GoBackButton w="110px" />
        <Spacer />
        <VStack pr="110px">
          <Heading fontSize={"2xl"} textAlign={"left"}>
            {"Group " + (groupInfo?.name || "")}
          </Heading>
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
        </VStack>
        <Spacer />
      </HStack>
      <VStack bg="white" rounded="md" w="100%">
        <HStack mb={4} w="70%">
          <Center w="100%">
            {groupInfoLoaded ? (
              GroupFields(groupInfo, getGroupInfo, updateGroupByID)
            ) : (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            )}
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

function GroupFields(groupInfo, getGroupInfo, updateGroupByID) {
  return (
    <VStack w="100%" bg="white" borderRadius="lg" spacing={10} mt={10}>
      <GenericEditableInput
        label="Name"
        icon={<BiGroup color="gray.800" />}
        value={groupInfo?.name}
        onUpdate={(new_name) => {
          updateGroupByID(groupInfo.id, {
            name: new_name,
          }).then((response) => {
            // After updating we need to reload group info
            if (response?.status == 200) {
              getGroupInfo(groupInfo.id);
            }
          });
        }}
      />
      <Person
        label="Contact"
        account_id={groupInfo?.account_id}
        person_id={groupInfo?.contact_id}
        onCreatePerson={(new_contact_id) => {
          updateGroupByID(groupInfo.id, {
            contact_id: new_contact_id,
          }).then((response) => {
            // After updating we need to reload group info
            if (response?.status == 200) {
              getGroupInfo(groupInfo.id);
            }
          });
        }}
      />
      <GroupAddress group_id={groupInfo.id} />
      <EditableSwitch
        label="Group Enabled"
        value={groupInfo?.enabled}
        onUpdate={(new_enabled) => {
          updateGroupByID(groupInfo.id, {
            enabled: new_enabled,
          }).then((response) => {
            // After updating we need to reload group info
            if (response?.status == 200) {
              getGroupInfo(groupInfo.id);
            }
          });
        }}
      />
    </VStack>
  );
}

export default GroupInfo;
