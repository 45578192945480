import React, { useContext } from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  StackDivider,
  Switch,
  VStack,
  HStack,
  Spacer,
  Center,
} from "@chakra-ui/react";

import { ProductAPI } from "../../api/ProductAPI";
import { AccountContext } from "../../context/AccountContext";

const ProductForm = () => {
  const [submittingData, setSubmittingData] = React.useState(false);
  const workingAccount = useContext(AccountContext);
  const [submitProduct] = ProductAPI.postProduct();

  return (
    <VStack spacing={4} padding={4}>
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"left"}>
          Create product
        </Heading>
      </Stack>
      <Box bg="white" w="50vh">
        <HStack mb={4} w="100%">
          <Spacer />
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
          <Spacer />
        </HStack>
        <Formik
          initialValues={{
            productName: "",
            productCode: "",
            productDescription: "",
            productEnabled: true,
            accountId: "",
          }}
          onSubmit={async (values, { resetForm }) => {
            submitProduct({
              name: values["productName"],
              code: values["productEnabled"],
              description: values["productDescription"],
              enabled: values["productEnabled"],
              account_id: workingAccount.id,
            }).then((response) => {
              setSubmittingData(false);
              if (response?.status == 200) {
                resetForm();
              }
            });
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={errors.productName && touched.productName}
                >
                  <FormLabel htmlFor="name">New product name</FormLabel>
                  <Field
                    as={Input}
                    id="productName"
                    name="productName"
                    type="productName"
                    variant="filled"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Product name is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.productName}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={errors.productName && touched.productName}
                >
                  <FormLabel htmlFor="name">Code</FormLabel>
                  <Field
                    as={Input}
                    id="productCode"
                    name="productCode"
                    type="productCode"
                    variant="filled"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Product code is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.productName}</FormErrorMessage>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="name">Description</FormLabel>
                  <Field
                    as={Input}
                    id="productDescription"
                    name="productDescription"
                    type="name"
                    variant="filled"
                  />
                </FormControl>

                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="productEnabled" mb="0">
                    Enabled
                  </FormLabel>
                  <Field id="productEnabled" name="productEnabled">
                    {({ field, form }) => (
                      <Switch
                        size="md"
                        defaultChecked
                        onChange={(e) => {
                          form.setFieldValue(field.name, e.target.checked);
                        }}
                      />
                    )}
                  </Field>
                </FormControl>

                <Center w="100%">
                  <Button
                    isLoading={submittingData}
                    loadingText="Submitting"
                    type="submit"
                    colorScheme="purple"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      submittingData
                    }
                    onClick={(value) => {
                      handleSubmit(value);
                      setSubmittingData(true);
                    }}
                  >
                    Create product
                  </Button>
                </Center>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default ProductForm;
