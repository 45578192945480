import React from "react";
import { Link as ReachLink } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import { IsAuth } from "../../components/Auth";

export default function RestrictedLink(props) {
  const isAuth = IsAuth();
  return isAuth(props.allowedRoles) ? (
    <ReachLink to={props.to}>{props.children}</ReachLink>
  ) : (
    <Text>{props.children}</Text>
  );
}
