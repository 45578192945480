import React, { useMemo, useContext, useCallback } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Heading, Stack, StackDivider, VStack, Text } from "@chakra-ui/react";
import { LicenseAPI } from "../../api/LicenseAPI";
import { AccountContext } from "../../context/AccountContext";
import { GoToEditPageButton } from "../utils/Buttons";
import RestrictedLink from "../utils/RestrictedLink";
import FilterTable from "../utils/FilterTable";
import { ROLES } from "../../components/Auth";

const LicensesTable = () => {
  const workingAccount = useContext(AccountContext);
  const [requestLicenses, licensesAmount, licenses] =
    LicenseAPI.licensesByAccount();

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "getID" },
      { Header: "Software", accessor: "getSoftware" },
      { Header: "Group", accessor: "getGroup" },
      { Header: "Created Date", accessor: "getCreatedDate" },
      { Header: "Expiration Date", accessor: "getExpDate" },
      { Header: "Sessions (Max)", accessor: "getSessionsInfo" },
      { Header: "Session Time Out", accessor: "getSessionTimeout" },
      { Header: "Enabled", accessor: "getEnabled" },
      { Header: "", accessor: "getEditButton", disableFilters: true },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (licenses !== null && licenses !== undefined) {
      licenses.map((license) => {
        values.push({
          getID: (
            <RestrictedLink
              allowedRoles={[ROLES.SUPER_USER]}
              to={"/licenses/" + license.id}
            >
              {license.id}
            </RestrictedLink>
          ),
          getSoftware: (
            <RestrictedLink
              allowedRoles={[ROLES.SUPER_USER]}
              to={"/softwares/" + license.software.id}
            >
              {license.software.name}
            </RestrictedLink>
          ),
          getGroup: (
            <ReachLink to={"/groups/" + license.group_id}>
              {license.group.name}
            </ReachLink>
          ),
          getCreatedDate: new Date(license.created_at).toLocaleDateString(),
          getExpDate: new Date(license.expiration_time).toLocaleDateString(),
          getSessionsInfo: (
            <Text>
              {license.active_sessions} ( {license.maximum_active_sessions} )
            </Text>
          ),
          getSessionTimeout: license.session_time_out,
          getEnabled: license.enabled ? "yes" : "no",
          getEditButton: <GoToEditPageButton to={"/licenses/" + license.id} />,
        });
      });
    }

    return values;
  }, [licenses]);

  const fetchData = useCallback(
    (pageSize, pageIndex) => {
      requestLicenses(workingAccount.id, pageSize * pageIndex, pageSize);
    },
    [workingAccount.id]
  );

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Licenses Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Licenses"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={licensesAmount}
        resetTableDependencies={[workingAccount.id]}
      />
    </VStack>
  );
};

export default LicensesTable;
