import { useState, useEffect, useContext, useMemo } from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  Select,
  Switch,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  StackDivider,
  VStack,
  HStack,
  Spacer,
  Spinner,
  Center
} from "@chakra-ui/react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from "@chakra-ui/react";
import { LicenseAPI } from "../../api/LicenseAPI";
import { GroupAPI } from "../../api/GroupAPI";
import { SoftwareAPI } from "../../api/SoftwareAPI";
import { AccountContext } from "../../context/AccountContext";

import { DatePicker } from "../utils/time/DatePicker";
import { today, now, getLocalTimeZone } from "@internationalized/date";

const LicenseForm = () => {
  const [submittingData, setSubmittingData] = useState(false);
  const workingAccount = useContext(AccountContext);
  const [
    getGroupsByAccount,
    ,
    groups,
    groupsLoaded
  ] = GroupAPI.groupsByAccount();
  const [
    getSoftwares,
    ,
    softwares,
    softwaresLoaded
  ] = SoftwareAPI.getSoftwares();
  const [submitLicense] = LicenseAPI.postLicense();

  useEffect(() => {
    getSoftwares();
    getGroupsByAccount(workingAccount.id);
  }, [workingAccount.id]);

  return (
    <>
      {groupsLoaded && softwaresLoaded ? (
        <VStack spacing={4} padding={4}>
          <Stack>
            <Heading fontSize={"2xl"} textAlign={"left"}>
              Create new VisionX License
            </Heading>
          </Stack>
          <Box bg="white" w="50vh">
            <HStack mb={4} w="100%">
              <Spacer />
              <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
              <Spacer />
            </HStack>
            <Formik
              initialValues={{
                licenseSoftwareId: "",
                licenseGroupId: "",
                licenseUpdatePolicy: 1,
                licenseMaximumActiveSessions: 0,
                licenseEnabled: true,
                licenseSessionTimeOutH: 0,
                licenseSessionTimeOutM: 0,
                licenseExpirationTime: ""
              }}
              onSubmit={async (values, { resetForm }) => {
                submitLicense({
                  software_id: values["licenseSoftwareId"],
                  group_id: values["licenseGroupId"],
                  update_policy: values["licenseUpdatePolicy"],
                  maximum_active_sessions:
                    values["licenseMaximumActiveSessions"],
                  expiration_time: values["licenseExpirationTime"],
                  enabled: values["licenseEnabled"],
                  session_time_out:
                    Number(values["licenseSessionTimeOutH"] * 60) +
                    Number(values["licenseSessionTimeOutM"])
                }).then(response => {
                  setSubmittingData(false);
                  if (response?.status == 200) {
                    resetForm();
                  }
                });
              }}
            >
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack spacing={4} align="flex-start">
                    <FormControl
                      isRequired
                      isInvalid={
                        errors.licenseSoftwareId && touched.licenseSoftwareId
                      }
                    >
                      <FormLabel htmlFor="name">Software</FormLabel>
                      <Field
                        as={Select}
                        id="licenseSoftwareId"
                        name="licenseSoftwareId"
                        type="licenseSoftwareId"
                        placeholder="Select software"
                        validate={value => {
                          let error;
                          if (!value) {
                            error = "Software is required";
                          }
                          return error;
                        }}
                      >
                        {softwares.map(software => (
                          <option key={software.id} value={software.id}>
                            {" "}
                            {software.name}{" "}
                          </option>
                        ))}
                      </Field>
                      <FormErrorMessage>
                        {errors.licenseSoftwareId}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isRequired
                      isInvalid={
                        errors.licenseGroupId && touched.licenseGroupId
                      }
                    >
                      <FormLabel htmlFor="name">Group</FormLabel>
                      <Field
                        as={Select}
                        id="licenseGroupId"
                        name="licenseGroupId"
                        type="licenseGroupId"
                        placeholder="Select group"
                        validate={value => {
                          let error;
                          if (!value) {
                            error = "Group is required";
                          }
                          return error;
                        }}
                      >
                        {groups.map(group => (
                          <option key={group.id} value={group.id}>
                            {" "}
                            {group.name}{" "}
                          </option>
                        ))}
                      </Field>
                      <FormErrorMessage>
                        {errors.licenseGroupId}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl hidden={true} isRequired>
                      <FormLabel htmlFor="name">Update Policy</FormLabel>
                      <Field
                        as={Input}
                        id="licenseUpdatePolicy"
                        name="licenseUpdatePolicy"
                        type="name"
                        variant="filled"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="name">
                        Maximum Active Sessions
                      </FormLabel>
                      <Field name="licenseMaximumActiveSessions">
                        {({ field, form }) => (
                          <NumberInput
                            defaultValue={1}
                            min={0}
                            id="licenseMaximumActiveSessions"
                            {...field}
                            onChange={val =>
                              form.setFieldValue(field.name, val)
                            }
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        )}
                      </Field>
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="name">Session Time Out</FormLabel>
                      <HStack spacing="24px">
                        <Field name="licenseSessionTimeOutH">
                          {({ field, form }) => (
                            <NumberInput
                              defaultValue={1}
                              min={0}
                              id="licenseSessionTimeOutH"
                              {...field}
                              onChange={val =>
                                form.setFieldValue(field.name, val)
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          )}
                        </Field>
                        <FormLabel htmlFor="name">hours</FormLabel>
                        <Field name="licenseSessionTimeOutM">
                          {({ field, form }) => (
                            <NumberInput
                              defaultValue={0}
                              min={0}
                              max={59}
                              id="licenseSessionTimeOutM"
                              {...field}
                              onChange={val =>
                                form.setFieldValue(field.name, val)
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          )}
                        </Field>
                        <FormLabel htmlFor="name">minutes</FormLabel>
                      </HStack>
                    </FormControl>

                    <FormControl
                      isRequired
                      isInvalid={
                        errors.licenseExpirationTime &&
                        touched.licenseExpirationTime
                      }
                    >
                      <Field
                        id="licenseExpirationTime"
                        name="licenseExpirationTime"
                        type="licenseExpirationTime"
                        validate={value => {
                          let error;
                          if (!value) {
                            error = "Expiration date is required";
                          }
                          return error;
                        }}
                      >
                        {({ field, form }) => (
                          <DatePicker
                            label="Expiration Date"
                            minValue={today(getLocalTimeZone())}
                            defaultValue={now(getLocalTimeZone())}
                            onChange={val => {
                              if (val) {
                                form.setFieldValue(
                                  field.name,
                                  val.toAbsoluteString()
                                );
                              }
                            }}
                          />
                        )}
                      </Field>
                      <FormErrorMessage>
                        {errors.licenseExpirationTime}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="licenseEnabled" mb="0">
                        License enabled
                      </FormLabel>
                      <Field id="licenseEnabled" name="licenseEnabled">
                        {({ field, form }) => (
                          <Switch
                            size="md"
                            defaultChecked
                            onChange={e => {
                              form.setFieldValue(field.name, e.target.checked);
                            }}
                          />
                        )}
                      </Field>
                    </FormControl>

                    <Center w="100%">
                      <Button
                        isLoading={submittingData}
                        loadingText="Submitting"
                        type="submit"
                        colorScheme="purple"
                        disabled={
                          Object.keys(errors).length > 0 ||
                          Object.keys(touched).length === 0 ||
                          submittingData
                        }
                        onClick={value => {
                          handleSubmit(value);
                          setSubmittingData(true);
                        }}
                      >
                        Create license
                      </Button>
                    </Center>
                  </VStack>
                </form>
              )}
            </Formik>
          </Box>
        </VStack>
      ) : (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
          label="Loading"
        />
      )}
    </>
  );
};

export default LicenseForm;
