import React, { useEffect } from "react";
import { useParams, Link as ReachLink } from "react-router-dom";
import {
  Heading,
  StackDivider,
  Center,
  VStack,
  HStack,
  Spacer,
  Spinner,
  UnorderedList,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { TbDental } from "react-icons/tb";
import { MdDescription } from "react-icons/md";
import { AiOutlineBarcode } from "react-icons/ai";
import { GoBackButton } from "../utils/Buttons";
import { ProductAPI } from "../../api/ProductAPI";
import GenericEditableInput from "../utils/editable/Generic";
import EditableSwitch from "../utils/editable/EditableSwitch";

const ProductInfo = () => {
  const params = useParams();
  const productID = params.productID;
  const [getProductInfo, productInfo, productInfoLoaded] =
    ProductAPI.getProductByID();
  const updateProductByID = ProductAPI.updateProductByID();

  useEffect(() => {
    getProductInfo(productID);
  }, [productID]);

  return (
    <VStack spacing={4} padding={4} margin={10}>
      <HStack w="100%">
        <GoBackButton w="110px" />
        <Spacer />
        <VStack pr="110px">
          <Heading fontSize={"2xl"} textAlign={"left"}>
            {"Product: " + (productInfo?.name || "")}
          </Heading>
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
        </VStack>
        <Spacer />
      </HStack>
      <VStack bg="white" rounded="md" w="100%">
        <HStack mb={4} w="70%">
          <Center w="100%">
            {productInfoLoaded ? (
              <VStack w="100%" spacing={3}>
                <UnorderedList spacing={2}>
                  <ListItem>
                    <HStack w="100%">
                      <Text size="sm" fontWeight="bold">
                        Account ID:
                      </Text>
                      <ReachLink to={"/accounts/" + productInfo.account_id}>
                        {productInfo.account_id}
                      </ReachLink>
                    </HStack>
                  </ListItem>
                </UnorderedList>
                <ProductFields
                  productInfo={productInfo}
                  updateProductByID={updateProductByID}
                  getProductInfo={getProductInfo}
                />
              </VStack>
            ) : (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            )}
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

function ProductFields({ productInfo, updateProductByID, getProductInfo }) {
  return (
    <VStack w="100%" bg="white" borderRadius="lg" spacing={10} mt={10}>
      <GenericEditableInput
        label="Name"
        icon={<TbDental color="gray.800" />}
        value={productInfo?.name}
        onUpdate={(new_name) => {
          updateProductByID(productInfo.id, {
            name: new_name,
          }).then((response) => {
            // After updating we need to reload product info
            if (response?.status == 200) {
              getProductInfo(productInfo.id);
            }
          });
        }}
      />
      <GenericEditableInput
        label="Code"
        icon={<AiOutlineBarcode color="gray.800" />}
        value={productInfo?.code}
        onUpdate={(new_code) => {
          updateProductByID(productInfo.id, {
            code: new_code,
          }).then((response) => {
            // After updating we need to reload product info
            if (response?.status == 200) {
              getProductInfo(productInfo.id);
            }
          });
        }}
      />
      <GenericEditableInput
        label="Description"
        icon={<MdDescription color="gray.800" />}
        value={productInfo?.description}
        onUpdate={(new_description) => {
          updateProductByID(productInfo.id, {
            description: new_description,
          }).then((response) => {
            // After updating we need to reload product info
            if (response?.status == 200) {
              getProductInfo(productInfo.id);
            }
          });
        }}
      />
      <EditableSwitch
        label="Product Enabled"
        value={productInfo?.enabled}
        onUpdate={(new_enabled) => {
          updateProductByID(productInfo.id, {
            enabled: new_enabled,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getProductInfo(productInfo.id);
            }
          });
        }}
      />
    </VStack>
  );
}

export default ProductInfo;
