import { useNavigate } from "react-router-dom"
import * as React from 'react';
import {
    Container,
    Button,
    Heading,
    Icon,
    Text,
    VStack,
    Stack,
    Divider,
    Center
} from '@chakra-ui/react';

import { IconContext } from "react-icons";
import { RiAlarmWarningFill } from "react-icons/ri";


const Unauthorized = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    return (
        <Container maxW="7xl" p={{ base: 5, md: 10 }} mx="auto">
            <Center>
                <VStack spacing={4} px={2} alignItems={{ base: 'center', sm: 'center' }}>
                    <Stack justifyContent="center" alignItems="center">
                    <IconContext.Provider value={{ color: 'red', size: 70 }}>
                        <RiAlarmWarningFill />
                    </IconContext.Provider >
                    </Stack>
                    <Heading
                        textAlign={{ base: 'center', sm: 'left' }}
                        margin="0 auto"
                        width={{ base: '23rem', sm: 'auto' }}
                        fontSize={{ base: '2.5rem', sm: '3rem' }}
                    >
                        Unauthorized!
                    </Heading>
                    <Text textAlign="center">You do not have access to the requested page.</Text>
                    <Divider />
                    <Button
                      type="submit"
                      colorScheme="blue"
                      onClick={goBack}>
                      Go back
                    </Button>
                </VStack>
            </Center>
        </Container>
    );
};

export default Unauthorized;
