import { useState, useContext, useRef } from "react";
import axios from "../api/axios";
import {
  popupError,
  popupSuccess,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { AuthContext } from "../context/AuthContext";
import { APIUtils } from "./Utils";
import { UserAPI } from "./UserAPI";

export class AccountAPI {
  static getAccounts = () => {
    return APIUtils.dataByRange(C.GET_ACCOUNTS_URL);
  };

  static accountByName = (account_name) => {
    const popup = GetPopup();
    const auth = useContext(AuthContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };

    const getData = async () => {
      try {
        const response = await axios.get(
          C.GET_ACCOUNT_BY_NAME_URL(account_name),
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + auth.token,
            },
            signal: controllerRef.current.signal,
          }
        );
        setData(response?.data);
        setLoaded(true);
      } catch (err) {
        popupError(popup, `Unable to get info about account: ${account_name}`);
        setError(err?.response);
        console.log(err?.response);
      }
    };

    return [getData, data, loaded, error, cancel];
  };

  static postAccountWithAdmin = () => {
    const popup = GetPopup();
    const auth = useContext(AuthContext);
    const [sendEmail, setSendEmail] = useState(true);

    const submitAccountWithAdmin = async (values) => {
      let response = null;
      try {
        const data = {
          account: values["account"],
          user: values["user"],
        };

        response = await axios.post(C.CREATE_ACCOUNT_WITH_ADMIN_URL, data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        });
        if (response?.data?.user?.id && sendEmail) {
          response = await UserAPI.sendSignupTokenEmail(
            response.data.user.id,
            values["tokenLifetime"],
            auth.token
          );
          popupSuccess(popup, response?.data);
        }
        popupSuccess(popup, "Account and admin created successfully");
      } catch (err) {
        popupError(popup, "Cannot create account", err?.response?.data?.detail);
        console.log("err_respons=", JSON.stringify(err));
      }

      return response;
    };
    return [setSendEmail, submitAccountWithAdmin];
  };

  static getAccountName = async (account_id, token, setWorkingAccountName) => {
    if (account_id && token && token != "null") {
      try {
        const response = await axios.get(
          C.GET_ACCOUNTS_NAME_BY_ACCOUNT_ID_URL(account_id),
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        setWorkingAccountName(response?.data);
      } catch (err) {
        console.log("ERROR: " + JSON.stringify(err?.response));
      }
    }
  };

  static updateAccount = () => {
    const auth = useContext(AuthContext);
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "Account updated successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot update account", detail);
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };
    const patchMethod = async (account_id, data) => {
      let response = null;
      try {
        response = await axios.patch(
          C.UPDATE_ACCOUNT_BY_ID_URL(account_id),
          data,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + auth.token,
            },
            signal: controllerRef.current.signal,
          }
        );
        onSuccess?.();
      } catch (err) {
        console.log("ERROR: " + JSON.stringify(err?.response));
        onError?.(err?.response?.data?.detail);
      }
      return response;
    };

    return patchMethod;
  };
}
