import React, { useMemo, useContext, useCallback } from "react";
import { Heading, Stack, StackDivider, VStack } from "@chakra-ui/react";
import FilterTable from "../utils/FilterTable";
import { AccountContext } from "../../context/AccountContext";
import { ProductionAPI } from "../../api/ProductionAPI";
import { calculateDiffTime, splitDateTime } from "../utils/Time";

const ProductionsTable = () => {
  const workingAccount = useContext(AccountContext);
  const [getProductionsByAccount, productionsAmount, productions, loaded] =
    ProductionAPI.productionsByAccount();

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "getID" },
      { Header: "Product Name", accessor: "getProductName" },
      { Header: "Count", accessor: "getProductionCount" },
      { Header: "Order Identifier", accessor: "getOrderIdentifier" },
      { Header: "Session start", accessor: "getSessionStart" },
      { Header: "Session end", accessor: "getSessionEnd" },
      { Header: "Elapsed time", accessor: "getElapsedTime" },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (productions !== null) {
      productions.map((production) => {
        values.push({
          getID: production.id,
          getProductName: production.product.name,
          getProductionCount: production.count,
          getOrderIdentifier: production.order_identifier,
          getSessionStart: splitDateTime(production.user_session.created_at),
          getSessionEnd: splitDateTime(production.user_session.end),
          getElapsedTime:
            production.user_session.end && production.user_session.created_at
              ? calculateDiffTime(
                  production.user_session.created_at,
                  production.user_session.end
                )
              : "--",
        });
      });
    }
    return values;
  }, [productions]);

  const fetchData = useCallback(
    (pageSize, pageIndex) => {
      getProductionsByAccount(
        workingAccount.id,
        pageSize * pageIndex,
        pageSize
      );
    },
    [workingAccount.id]
  );

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Productions Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Productions"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={productionsAmount}
        resetTableDependencies={[workingAccount.id]}
      />
    </VStack>
  );
};

export default ProductionsTable;
