import React, { createContext, useEffect, useState } from "react";
import { UserAPI } from "../api/UserAPI";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const [accountId, setAccountId] = useState(localStorage.getItem("accountId"));
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));
  const me = UserAPI.getUserMe();

  useEffect(() => {
    const fetchUser = () => {
      setUserId(me.userId);
      setUserName(me.userName);
      setAccountId(me.accountId);
      setUserRole(me.userRole);

      localStorage.setItem("userId", me.userId);
      localStorage.setItem("userName", me.userName);
      localStorage.setItem("accountId", me.accountId);
      localStorage.setItem("userRole", me.userRole);
    };
    fetchUser();
  }, [me]);

  return (
    <UserContext.Provider
      value={{
        id: userId,
        name: userName,
        account: accountId,
        role: userRole,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
