import React from "react";
import { useNavigate } from "react-router-dom";

import { Formik, Field } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  VStack,
  Center,
} from "@chakra-ui/react";
import { UserAPI } from "../api/UserAPI";
import PasswordInput from "./utils/PasswordInput";
import { DarkHomeLogo } from "./utils/HomeLogo";

const SetPassword = () => {
  const url = window.location.href;
  const navigate = useNavigate();
  const token = url.split("/").pop();
  const [passwordCreated, setPasswordCreated] = React.useState(false);
  const [submittingData, setSubmittingData] = React.useState(false);
  const submitSignup = UserAPI.patchSignup(token);
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bgGradient="radial(white, blue.50)"
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <DarkHomeLogo size="60px" />
          <Text hidden={passwordCreated} fontSize={"lg"} color={"gray.600"}>
            Set your user password
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          {passwordCreated ? (
            <Center w="100%">
              <Button
                w="100%"
                colorScheme="purple"
                onClick={() => navigate("/")}
              >
                {" "}
                Ready to login!{" "}
              </Button>
            </Center>
          ) : (
            <Formik
              initialValues={{
                password: "",
                password_confirmation: "",
              }}
              onSubmit={async (values, { setFieldError }) => {
                if (values["password_confirmation"] != values["password"]) {
                  setFieldError(
                    "password_confirmation",
                    "Passwords don't match"
                  );
                  setSubmittingData(false);
                  return;
                }
                submitSignup(values["password"]).then((response) => {
                  setSubmittingData(false);
                  setPasswordCreated(response?.status == 200);
                });
              }}
            >
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack spacing={4} align="flex-start">
                    <FormControl
                      isInvalid={errors.password && touched.password}
                    >
                      <FormLabel htmlFor="password">New password</FormLabel>
                      <Field
                        as={PasswordInput}
                        id="password"
                        name="password"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          if (!value) {
                            error = "Password is required";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        errors.password_confirmation &&
                        touched.password_confirmation
                      }
                    >
                      <FormLabel htmlFor="password_confirmation">
                        Confirm new password
                      </FormLabel>
                      <Field
                        as={PasswordInput}
                        id="password_confirmation"
                        name="password_confirmation"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          if (!value) {
                            error = "Password confirmation is required";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage>
                        {errors.password_confirmation}
                      </FormErrorMessage>
                    </FormControl>
                    <Center w="100%">
                      <Button
                        isLoading={submittingData}
                        loadingText="Submitting"
                        type="submit"
                        colorScheme="purple"
                        disabled={
                          Object.keys(errors).length > 0 || submittingData
                        }
                        onClick={(value) => {
                          handleSubmit(value);
                          setSubmittingData(true);
                        }}
                      >
                        Signup
                      </Button>
                    </Center>
                  </VStack>
                </form>
              )}
            </Formik>
          )}
        </Box>
      </Stack>
    </Flex>
  );
};

export default SetPassword;
