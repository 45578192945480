import { useNavigate } from "react-router-dom";
import * as React from "react";
import { ChevronLeftIcon, EditIcon } from "@chakra-ui/icons";
import { Button, IconButton } from "@chakra-ui/react";

export const GoBackButton = (props) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <Button
      type="submit"
      leftIcon={<ChevronLeftIcon />}
      colorScheme="blue"
      onClick={goBack}
      align="center"
      {...props}
    >
      Go back
    </Button>
  );
};

export const EditIconButton = (props) => {
  return (
    <IconButton
      colorScheme="blue"
      aria-label="Edit"
      size="sm"
      _focus={{ outline: "none" }}
      icon={<EditIcon />}
      {...props}
    />
  );
};

export const EditButton = (props) => {
  const navigate = useNavigate();
  return (
    <Button rightIcon={<EditIcon />} colorScheme="linkedin" {...props}>
      Edit
    </Button>
  );
};

export const GoToEditPageButton = ({ to, props }) => {
  const navigate = useNavigate();
  return (
    <Button
      rightIcon={<EditIcon />}
      colorScheme="linkedin"
      variant="outline"
      onClick={() => {
        navigate(to);
      }}
      {...props}
    >
      Edit
    </Button>
  );
};
