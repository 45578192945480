export function calculateDiffTime(createdAt, end) {
    const createdAtDate = new Date(createdAt);
    const endDate = new Date(end);
    const secNum = Math.floor(Math.abs(endDate - createdAtDate) / 1000);
    var hours = Math.floor(secNum / 3600);
    var minutes = Math.floor((secNum - hours * 3600) / 60);
    var seconds = secNum - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }

export function splitDateTime(dateTime){
  const localeDate = new Date(dateTime).toLocaleDateString()
  const localeTime = new Date(dateTime).toLocaleTimeString()
  return localeDate + " " + localeTime
}
