import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

const HOURS_TO_MINUTE = 60;

export class ProductionAPI {
  static productionsByAccount = () => {
    return APIUtils.dataByAccountByRange(
      C.GET_PRODUCTIONS_BY_ACCOUNT_URL,
      "Unable to load productions"
    );
  };
}
