import React from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export default function PasswordInput(props) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input pr="4.5rem" type={show ? "text" : "password"} {...props} />
      <InputRightElement width="4.5rem">
        <IconButton
          h="1.75rem"
          size="sm"
          colorScheme="blue"
          icon={show ? <ViewOffIcon /> : <ViewIcon />}
          onClick={handleClick}
        ></IconButton>
      </InputRightElement>
    </InputGroup>
  );
}
