import { useState, useContext, useEffect } from "react";
import axios from "../api/axios";
import { useAxiosPatch } from "../hooks/UseAxios";
import {
  popupError,
  popupSuccess,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { AuthContext, AuthLogout } from "../context/AuthContext";
import { AccountContext } from "../context/AccountContext";
import { APIUtils } from "./Utils";

const HOURS_TO_MINUTE = 60;

export class UserAPI {
  static getUserMe = () => {
    const logout = AuthLogout();
    const auth = useContext(AuthContext);
    const [me, setMe] = useState({
      userId: null,
      userName: null,
      userRole: null,
      accountId: null,
    });

    useEffect(() => {
      const fetchMe = async () => {
        try {
          const response = await axios.get(C.USER_ME_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            },
          });
          setMe({
            userId: response?.data?.id,
            userName: response?.data?.name,
            userRole: response?.data?.role?.name,
            accountId: response?.data?.account_id,
          });
        } catch (err) {
          console.log("ERR" + err?.response);
          /* TODO: Now, we depend on the GetMe failure to blank contexts and redirect to the Login page.
                             We should improve the Logout logic to fix all this.*/
          logout();
          setMe({
            userId: null,
            userName: null,
            userRole: null,
            accountId: null,
          });
        }
      };
      if (auth.token && auth.token !== "null") {
        fetchMe();
      } else {
        logout();
        setMe({
          userId: null,
          userName: null,
          userRole: null,
          accountId: null,
        });
      }
    }, [auth.token]);

    return me;
  };

  static usersByAccount = () => {
    return APIUtils.dataByAccountByRange(
      C.GET_USERS_IN_ACCOUNT_URL,
      "Unable to load users"
    );
  };

  static postUsers = () => {
    const popup = GetPopup();
    const [userEnabled, setUserEnabled] = useState(true);
    const [sendEmail, setSendEmail] = useState(true);
    const auth = useContext(AuthContext);
    const workingAccount = useContext(AccountContext);

    const submitUser = async (values) => {
      let response = null;
      try {
        const data = {
          name: values["userName"],
          email: values["userEmail"],
          enabled: userEnabled,
          account_id: workingAccount.id,
          groups: values["userGroups"],
        };

        response = await axios.post(C.CREATE_USER_URL, data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        });

        console.log("Creating user");

        if (response?.data?.id && data["groups"]) {
          data["groups"].forEach((group) => {
            const group_user = {
              user_id: response.data.id,
              group_id: parseInt(group),
              enabled: true,
            };
            axios.post(C.CREATE_GROUP_USER, group_user, {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + auth.token,
              },
            });
          });
        }
        if (response?.data?.id && sendEmail) {
          const email_response = await this.sendSignupTokenEmail(
            response.data.id,
            values["tokenLifetime"],
            auth.token
          );
          console.log("email_response=", JSON.stringify(email_response));
          popupSuccess(popup, email_response?.data);
        }
        popupSuccess(popup, "User created successfully");
      } catch (err) {
        popupError(popup, "Cannot create user", err?.response?.data?.detail);
        console.log("err_respons=", JSON.stringify(err?.response));
      }

      return response;
    };

    return [setUserEnabled, setSendEmail, submitUser];
  };

  static updateUserMe = () => {
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "User updated successfully");
    const onError = (detail) => popupError(popup, "Cannot update user", detail);
    return useAxiosPatch(C.UPDATE_USER_ME, onSuccess, onError);
  };

  static getUserByID = () => {
    return APIUtils.getDataByID(
      C.GET_USER_BY_ID_URL,
      "Unable to get user information"
    );
  };

  static updateUserByID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_USER_BY_ID_URL,
      "User updated successfully",
      "Unable to update user"
    );
  };

  static patchSignup = (token) => {
    const popup = GetPopup();
    const submitSignup = async (password) => {
      let response = null;
      try {
        const data = { password: password };
        response = await axios.patch(C.SIGNUP_URL, data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        popupSuccess(popup, "Password updated successfully");
      } catch (err) {
        popupError(
          popup,
          "Cannot update password",
          err?.response?.data?.detail
        );
        console.log("ERROR: " + JSON.stringify(err?.response));
      }

      return response;
    };
    return submitSignup;
  };

  static resetPasswordUser = (userID) => {
    const popup = GetPopup();
    const auth = useContext(AuthContext);

    const resetPassword = async () => {
      let response = null;
      try {
        response = await this.sendSignupTokenEmail(
          userID,
          C.TOKEN_LIFETIME,
          auth.token
        );
        popupSuccess(popup, "Reset password email sent");
      } catch (err) {
        popupError(
          popup,
          "Cannot send reset password email",
          err?.response?.data?.detail
        );
        console.log("ERROR: " + JSON.stringify(err?.response));
      }

      return response;
    };

    return resetPassword;
  };

  static sendSignupTokenEmail = async (userId, tokenLifetime, authToken) => {
    const token_request_data = {
      user_id: userId,
      lifetime: tokenLifetime * HOURS_TO_MINUTE,
    };
    const email_response = await axios.post(
      C.SEND_SIGNUP_TOKEN_EMAIL,
      token_request_data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      }
    );
    return email_response;
  };
}
