import axios from "../api/axios";
import { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../context/AuthContext";

export function useAxiosGet(get_url, onSuccess, onError, depends = []) {
  const auth = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(get_url, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          signal: controllerRef.current.signal,
        });
        setData(response?.data);
        setLoaded(true);
        onSuccess?.();
      } catch (err) {
        onError?.(err?.response.data.detail);
        setError(err?.response);
        console.log("ERROR", err?.response);
      }
    };
    getData();
  }, depends);

  return [data, loaded, error, cancel];
}

export function useAxiosPost(post_url, onSuccess, onError) {
  const auth = useContext(AuthContext);
  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };
  const postMethod = async (data) => {
    let response = null;
    try {
      response = await axios.post(post_url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        signal: controllerRef.current.signal,
      });
      onSuccess?.();
    } catch (err) {
      console.log("ERROR: " + JSON.stringify(err?.response));
      onError?.(err?.response?.data?.detail);
    }
    return response;
  };

  return [postMethod, cancel];
}

export function useAxiosPatch(patch_url, onSuccess, onError) {
  const auth = useContext(AuthContext);
  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };
  const patchMethod = async (data) => {
    let response = null;
    try {
      response = await axios.patch(patch_url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        signal: controllerRef.current.signal,
      });
      onSuccess?.();
    } catch (err) {
      console.log("ERROR: " + JSON.stringify(err?.response));
      onError?.(err?.response?.data?.detail);
    }
    return response;
  };

  return [patchMethod, cancel];
}

export function useAxiosDelete(patch_url, onSuccess, onError) {
  const auth = useContext(AuthContext);
  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };
  const deleteMethod = async (data) => {
    let response = null;
    try {
      response = await axios.delete(patch_url, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        data: data,
        signal: controllerRef.current.signal,
      });
      onSuccess?.();
    } catch (err) {
      console.log("ERROR: " + JSON.stringify(err?.response));
      onError?.(err?.response?.data?.detail);
    }
    return response;
  };

  return [deleteMethod, cancel];
}

export function useAxiosGetV2(get_url, onSuccess, onError) {
  const auth = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };

  const getData = async () => {
    let response = null;
    if (auth?.token && auth?.token !== "null") {
      try {
        response = await axios.get(get_url, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          signal: controllerRef.current.signal,
        });
        setData(response?.data);
        setLoaded(true);
        onSuccess?.();
      } catch (err) {
        onError?.(err?.response?.data?.detail);
        setError(err?.response);
        console.log("ERROR", get_url, err);
      }
    }

    return response;
  };

  return [getData, data, loaded, error, cancel];
}
