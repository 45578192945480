import { useLocation, Navigate, Outlet } from "react-router-dom";
import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";

export const ROLES = {
    "SUPER_USER": "SUPER_USER",
    "VISIONX_ADMIN": "VISIONX_ADMIN",
    "ACCOUNT_ADMIN": "ACCOUNT_ADMIN",
    "USER": "USER"
}

export const IsAuth = () => {
    const user = useContext(UserContext);
    const checkIsAuth = (allowedRoles) => {
        return allowedRoles?.includes(user.role)
    }
    return checkIsAuth;
}

export const PathAuth = ({ allowedRoles }) => {
    const isAuth = IsAuth();
    const location = useLocation();
    return (
        isAuth(allowedRoles) ? <Outlet />
            : <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
}
