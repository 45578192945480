import React, { createContext, useContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [tok, setTok] = useState(localStorage.getItem("visionxToken"));

  return (
    <AuthContext.Provider value={{
      token : tok,
      setToken : setTok,
    }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export const AuthLogin = () => {
  const auth = useContext(AuthContext);
  const updateLogin = (token) => {
    auth.setToken(token);
    localStorage.setItem("visionxToken", token);
  };
  return updateLogin;
}

export const AuthLogout = () => {
  const auth = useContext(AuthContext);
  const updateLogin = () => {
    localStorage.setItem("visionxToken", null);
    auth.setToken(null);
  };
  return updateLogin;
}
