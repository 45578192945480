import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { AccountProvider } from "./context/AccountContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <AuthProvider>
    <UserProvider>
      <AccountProvider>
        <App />
      </AccountProvider>
    </UserProvider>
  </AuthProvider>
);
