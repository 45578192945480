import React, { useMemo, useContext, useCallback, useState } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Button, Heading, Stack, VStack, StackDivider } from "@chakra-ui/react";
import { SessionAPI } from "../../api/SessionAPI";
import { AccountContext } from "../../context/AccountContext";
import RestrictedLink from "../utils/RestrictedLink";
import FilterTable from "../utils/FilterTable";
import { calculateDiffTime, splitDateTime } from "../utils/Time";

import { ROLES } from "../../components/Auth";

const SessionsTable = () => {
  const workingAccount = useContext(AccountContext);
  const [requestSessions, sessionsAmount, sessions] =
    SessionAPI.sessionsByAccount();
  const endSession = SessionAPI.endSession();
  const [render, setRender] = useState(false);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "getID" },
      { Header: "User Name", accessor: "getUserName" },
      { Header: "License ID", accessor: "getLicenseID" },
      { Header: "Start time", accessor: "getCreatedAt" },
      { Header: "End time", accessor: "getEndTime" },
      { Header: "Duration", accessor: "getDuration", disableFilters: true },
      { Header: "Status", accessor: "getStatus" },
      { Header: "", accessor: "getEndSessionButton", disableFilters: true },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (sessions !== null && sessions !== undefined) {
      sessions.map((session) => {
        values.push({
          getID: session.id,
          getUserName: (
            <ReachLink to={"/users/" + session.user_id}>
              {session.user.name}
            </ReachLink>
          ),
          getLicenseID: (
            <RestrictedLink
              allowedRoles={[ROLES.SUPER_USER]}
              to={"/licenses/" + session.license_id}
            >
              {session.license_id}
            </RestrictedLink>
          ),
          getCreatedAt: splitDateTime(session.created_at),
          getEndTime: splitDateTime(session.end),
          getDuration:
            session.end && !session.is_active
              ? calculateDiffTime(session.created_at, session.end)
              : "",
          getStatus: convertSessionStatusToString(
            session.status,
            session.is_active
          ),
          getEndSessionButton: session.is_active ? (
            <Button
              colorScheme="red"
              variant="outline"
              onClick={() => {
                endSession(session.user_id, null).then(() => {
                  setRender(!render);
                });
              }}
            >
              End session
            </Button>
          ) : (
            ""
          ),
        });
      });
    }

    return values;
  }, [sessions]);

  const fetchData = useCallback(
    (pageSize, pageIndex) => {
      requestSessions(workingAccount.id, pageSize * pageIndex, pageSize);
    },
    [workingAccount.id, render]
  );

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Sessions Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Sessions"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={sessionsAmount}
        resetTableDependencies={[workingAccount.id]}
      />
    </VStack>
  );
};

const convertSessionStatusToString = (status, is_active) => {
  const Status = {
    Started: 1,
    Refreshed: 2,
    Closed: 3,
    Aborted: 4,
    Failed: 5,
  };

  switch (status) {
    case Status.Started:
      return is_active ? "Active" : "Finished by timeout";
    case Status.Refreshed:
      return is_active ? "Refreshed" : "Finished by timeout";
    case Status.Closed:
      return "Finished by user";
    case Status.Aborted:
      return "Aborted";
    case Status.Failed:
      return "Failed";
  }

  return "Unknown";
};

export default SessionsTable;
