import React, { useMemo, useCallback, useState } from "react";
import { Heading, Stack, VStack, StackDivider } from "@chakra-ui/react";
import { SessionAPI } from "../../api/SessionAPI";
import FilterTable from "../utils/FilterTable";

const MySessions = () => {
  const [requestSessions, sessionsAmount, sessions] = SessionAPI.sessionsMe();

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "getID" },
      { Header: "License ID", accessor: "getLicenseID" },
      { Header: "Start time", accessor: "getCreatedAt" },
      { Header: "End time", accessor: "getEndTime" },
      { Header: "Status", accessor: "getStatus" },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (sessions !== null && sessions !== undefined) {
      sessions.map((session) => {
        values.push({
          getID: session.id,
          getLicenseID: session.license_id,
          getCreatedAt: session.created_at,
          getEndTime: session.end,
          getStatus: convertSessionStatusToString(
            session.status,
            session.is_active
          ),
        });
      });
    }

    return values;
  }, [sessions]);

  const fetchData = useCallback((pageSize, pageIndex) => {
    requestSessions(pageSize * pageIndex, pageSize);
  }, []);

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Sessions Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Sessions"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={sessionsAmount}
        resetTableDependencies={[]}
      />
    </VStack>
  );
};

const convertSessionStatusToString = (status, is_active) => {
  const Status = {
    Started: 1,
    Refreshed: 2,
    Closed: 3,
    Aborted: 4,
    Failed: 5,
  };

  switch (status) {
    case Status.Started:
      return is_active ? "Active" : "Finished by timeout";
    case Status.Refreshed:
      return is_active ? "Refreshed" : "Finished by timeout";
    case Status.Closed:
      return "Finished by user";
    case Status.Aborted:
      return "Aborted";
    case Status.Failed:
      return "Failed";
  }

  return "Unknown";
};

export default MySessions;
