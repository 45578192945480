import { useRef, useState, useContext } from "react";
import axios from "../api/axios";
import { useAxiosGetV2, useAxiosPost, useAxiosPatch } from "../hooks/UseAxios";
import {
  popupError,
  popupSuccess,
  popupInfo,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { AuthContext } from "../context/AuthContext";
import { APIUtils } from "./Utils";

export class PersonAPI {
  static getPersonMe = () => {
    return useAxiosGetV2(C.GET_PERSON_ME_URL, null, null);
  };

  static postPersonMe = () => {
    const popup = GetPopup();
    const onError = (detail) =>
      popupInfo(popup, "Unable to create personal information");
    return useAxiosPost(C.CREATE_PERSON_ME_URL, null, onError);
  };

  static updatePersonMe = () => {
    const popup = GetPopup();
    const onSuccess = () =>
      popupSuccess(popup, "Personal info updated successfully");
    const onError = (detail) => popupError(popup, "Cannot update", detail);
    return useAxiosPatch(C.UPDATE_PERSON_ME, onSuccess, onError);
  };

  static getPersonByID = () => {
    return APIUtils.getDataByID(
      C.GET_PERSON_BY_ID_URL,
      "Unable to get info about person info"
    );
  };

  static postPerson = () => {
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "Person created successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot create person data", detail);
    return useAxiosPost(C.CREATE_PERSON_URL, onSuccess, onError);
  };

  static updatePersonByID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_PERSON_BY_ID_URL,
      "Personal information updated",
      "Unable to update personal info"
    );
  };

  static getContactsByAccountID = (account_id, contact_type) => {
    const auth = useContext(AuthContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };

    const getData = async () => {
      let response = null;
      try {
        response = await axios.get(
          C.GET_CONTACT_TYPE_BY_ACCOUNT_ID_URL(account_id) +
            `?account_contact_type=${contact_type}`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + auth.token,
            },
            signal: controllerRef.current.signal,
          }
        );
        setData(response?.data);
        setLoaded(true);
      } catch (err) {
        setError(err?.response);
        console.log("ERROR", err?.response);
      }

      return response;
    };

    return [getData, data, loaded, error, cancel];
  };

  static getPersonAddress = (person_id) => {
    return useAxiosGetV2(C.GET_PERSON_ADDRESS_URL(person_id), null, null);
  };

  static postPersonAddress = (person_id) => {
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "Address created successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot create address", detail);
    return useAxiosPost(
      C.CREATE_PERSON_ADDRESS_URL(person_id),
      onSuccess,
      onError
    );
  };

  static updatePersonAddress = (person_id) => {
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "Address updated successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot update address", detail);
    return useAxiosPatch(
      C.UPDATE_PERSON_ADDRESS_URL(person_id),
      onSuccess,
      onError
    );
  };
}
