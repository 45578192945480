import { VStack, Text, HStack, Spacer, Switch } from "@chakra-ui/react";
import { IoIosSwitch } from "react-icons/io";

export default function EditableSwitch({ label, value, onUpdate }) {
  return (
    <VStack w="100%" align="left">
      <HStack>
        <IoIosSwitch color="gray.800" />
        <Text align="left" fontWeight="bold">
          {label}
        </Text>
        <Spacer />
        <Switch
          isChecked={value}
          size="md"
          _hover={{ background: "none" }}
          _focus={{ outline: "none" }}
          onChange={(e) => {
            onUpdate(e?.target?.checked);
          }}
        />
      </HStack>
    </VStack>
  );
}
