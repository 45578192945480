import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

export class SessionAPI {
  static sessionsMe = () => {
    return APIUtils.dataByRange(
      C.GET_SESSIONS_ME_URL,
      "Unable to load sessions"
    );
  };

  static sessionsByAccount = () => {
    return APIUtils.dataByAccountByRange(
      C.GET_SESSIONS_BY_ACCOUNT_URL,
      "Unable to load sessions"
    );
  };

  static endSession = () => {
    return APIUtils.updateDataByID(
      C.END_SESSION_BY_USER_ID,
      "Session ended successfully",
      "Can not end session"
    );
  };
}
