import React, { useMemo, useCallback, useContext } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Heading, Stack, StackDivider, VStack } from "@chakra-ui/react";
import { UserAPI } from "../../api/UserAPI";
import { AccountContext } from "../../context/AccountContext";
import { GoToEditPageButton } from "../utils/Buttons";
import FilterTable from "../utils/FilterTable";

const UsersTable = () => {
  const workingAccount = useContext(AccountContext);
  const [getUsersByAcount, usersAmount, users] = UserAPI.usersByAccount();

  const columns = useMemo(
    () => [
      { Header: "Name", accessor: "getName" },
      { Header: "Email", accessor: "getEmail" },
      { Header: "Enabled", accessor: "getEnabled" },
      { Header: "Role", accessor: "getRole" },
      { Header: "", accessor: "getEditButton", disableFilters: true },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (users !== null) {
      users.map((user) => {
        values.push({
          getName: <ReachLink to={"/users/" + user.id}>{user.name}</ReachLink>,
          getEmail: user.email,
          getEnabled: user.enabled ? "Yes" : "No",
          getRole: user.role?.name,
          getEditButton: <GoToEditPageButton to={"/users/" + user.id} />,
        });
      });
    }

    return values;
  }, [users]);

  const fetchData = useCallback(
    (pageSize, pageIndex) => {
      getUsersByAcount(workingAccount.id, pageSize * pageIndex, pageSize);
    },
    [workingAccount.id]
  );

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Users Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Users"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={usersAmount}
        resetTableDependencies={[workingAccount.id]}
      />
    </VStack>
  );
};

export default UsersTable;
