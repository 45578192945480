import React from "react";
import { Stack, Box, useColorModeValue } from "@chakra-ui/react";

export default function Content({ children }) {
  const color = useColorModeValue("grey.200", "grey.200");

  return (
    <Stack h="89vh" w="90%" overflowY="auto" overflowX="auto">
      <Box marginTop="2.5vh" bg={color}>
        {children}
      </Box>
    </Stack>
  );
}
