import { useAxiosGetV2, useAxiosPost, useAxiosPatch } from "../hooks/UseAxios";
import {
  popupError,
  popupSuccess,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

export class AddressAPI {
  static getAddressMe = () => {
    return useAxiosGetV2(C.GET_ADDRESS_ME_URL, null, null);
  };

  static postAddressMe = () => {
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "Address created successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot create address", detail);
    return useAxiosPost(C.CREATE_ADDRESS_ME_URL, onSuccess, onError);
  };

  static updateAddressMe = () => {
    const popup = GetPopup();
    const onSuccess = () => popupSuccess(popup, "Address updated successfully");
    const onError = (detail) =>
      popupError(popup, "Cannot update address", detail);
    return useAxiosPatch(C.UPDATE_ADDRESS_ME_URL, onSuccess, onError);
  };

  static getAddressByGroupID = () => {
    return APIUtils.getDataByID(
      C.GET_ADDRESS_BY_GROUP_ID,
      "Unable to get group address information"
    );
  };

  static postAddressByGroupID = () => {
    return APIUtils.postDataByID(
      C.CREATE_ADDRESS_BY_GROUP_ID,
      "Unable to create group address information"
    );
  };

  static updateAddressByGroupID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_ADDRESS_BY_GROUP_ID,
      "Group address updated",
      "Unable to update group address information"
    );
  };

  static getAddressByAccountID = () => {
    return APIUtils.getDataByID(C.GET_ADDRESS_BY_ACCOUNT_ID);
  };

  static postAddressByAccountID = () => {
    return APIUtils.postDataByID(
      C.CREATE_ADDRESS_BY_ACCOUNT_ID,
      "Unable to create account address information"
    );
  };

  static updateAddressByAccountID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_ADDRESS_BY_ACCOUNT_ID,
      "Account address updated",
      "Unable to update account address information"
    );
  };
}
