import { useState, useEffect } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Spacer,
  Text,
  Grid,
  GridItem,
  Box,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { GrMapLocation } from "react-icons/gr";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { Formik, Field } from "formik";
import { EditIconButton } from "../Buttons";
import { AddressAPI } from "../../../api/AddressAPI";
import { PersonAPI } from "../../../api/PersonAPI";

const BG_COLOR_FORM = "#f3f3f3";

export function AddressMe() {
  const [getAddressFn, addressData] = AddressAPI.getAddressMe();
  const [postAddressFn] = AddressAPI.postAddressMe();
  const [updateAddressFn] = AddressAPI.updateAddressMe();
  return (
    <AddressEditableInput
      getAddressFn={getAddressFn}
      addressData={addressData}
      postAddressFn={postAddressFn}
      updateAddressFn={updateAddressFn}
      label={"Personal Address"}
    />
  );
}

export function PersonAddress({ person_id }) {
  const [getAddressFn, addressData] = PersonAPI.getPersonAddress(person_id);
  const [postAddressFn] = PersonAPI.postPersonAddress(person_id);
  const [updateAddressFn] = PersonAPI.updatePersonAddress(person_id);
  return (
    <AddressEditableInput
      getAddressFn={getAddressFn}
      addressData={addressData}
      postAddressFn={postAddressFn}
      updateAddressFn={updateAddressFn}
      label={"Personal Address"}
    />
  );
}

export function GroupAddress({ group_id, label = "Group Address" }) {
  const [getAddressFn, addressData] = AddressAPI.getAddressByGroupID();
  const [postAddressFn] = AddressAPI.postAddressByGroupID();
  const updateAddressFn = AddressAPI.updateAddressByGroupID();

  return (
    <AddressEditableInput
      getAddressFn={() => {
        return getAddressFn(group_id);
      }}
      addressData={addressData}
      postAddressFn={(data) => {
        return postAddressFn(group_id, data);
      }}
      updateAddressFn={(data) => {
        return updateAddressFn(group_id, data);
      }}
      label={label}
    />
  );
}

export function AccountAddress({ account_id, label = "Account Address" }) {
  const [getAddressFn, addressData] = AddressAPI.getAddressByAccountID();
  const [postAddressFn] = AddressAPI.postAddressByAccountID();
  const updateAddressFn = AddressAPI.updateAddressByAccountID();

  return (
    <AddressEditableInput
      getAddressFn={() => {
        return getAddressFn(account_id);
      }}
      addressData={addressData}
      postAddressFn={(data) => {
        return postAddressFn(account_id, data);
      }}
      updateAddressFn={(data) => {
        return updateAddressFn(account_id, data);
      }}
      label={label}
    />
  );
}

function AddressEditableInput({
  getAddressFn,
  addressData,
  postAddressFn,
  updateAddressFn,
  label = "Address",
}) {
  const [render, setRender] = useState(false);
  const [creationMode, setCreationMode] = useState(
    addressData == null || addressData == undefined
  );
  const { openModal, closeModal, ModalAddress } = CreateModalAddress();

  useEffect(() => {
    getAddressFn();
  }, [render, creationMode]);

  useEffect(() => {
    setCreationMode(addressData == null || addressData == undefined);
  }, [addressData]);

  return creationMode ? (
    <HStack w="100%" align="center">
      <GrMapLocation color="gray.800" />
      <Text w="30%" align="left" fontWeight="bold">
        {label}
      </Text>
      <Button
        w="30%"
        variant="outline"
        colorScheme="linkedin"
        leftIcon={<PlusSquareIcon />}
        _focus={{ outline: "none" }}
        onClick={openModal}
      >
        Address
      </Button>
      <Spacer />
      <ModalAddress
        addressData={addressData}
        acceptLabel="Create"
        onClickAccept={(address_values) => {
          postAddressFn(address_values).then((address_response) => {
            if (address_response?.status == 200) {
              setRender(!render);
            }
          });
          closeModal();
        }}
      />
    </HStack>
  ) : (
    <VStack align="left" w="100%">
      <HStack>
        <GrMapLocation color="gray.800" />
        <Text fontWeight="bold">{label}</Text>
        <Spacer />
        <EditIconButton onClick={openModal} />
        <ModalAddress
          addressData={addressData}
          acceptLabel="Update"
          onClickAccept={(address_values) => {
            updateAddressFn(address_values).then((address_response) => {
              if (address_response?.status == 200) {
                setRender(!render);
              }
            });
            closeModal();
          }}
        />
      </HStack>
      <HStack w="100%" bg={BG_COLOR_FORM} borderRadius="3px">
        <Accordion w="100%" colorScheme="red" allowToggle>
          <AccordionItem w="100%">
            <h2>
              <AccordionButton>
                <Box w="100%" textAlign="left">
                  {GetAddressSummary(addressData)}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>{AddressDetails(addressData)}</AccordionPanel>
          </AccordionItem>
        </Accordion>
      </HStack>
    </VStack>
  );
}

function AddressForm() {
  const getAddressForm = (label, id, props = { type: "text" }) => {
    return (
      <Field name={id}>
        {({ field }) => (
          <FormControl id={id}>
            <Grid templateColumns="repeat(11, 1fr)" gap={1}>
              <GridItem colSpan={3}>
                <FormLabel>{label}</FormLabel>
              </GridItem>
              <GridItem colSpan={8}>
                <Input
                  {...field}
                  {...props}
                  _hover={{ background: "none" }}
                  _focus={{ outline: "none" }}
                />
              </GridItem>
            </Grid>
          </FormControl>
        )}
      </Field>
    );
  };

  return (
    <HStack w="100%" m={1} align="left">
      <VStack spacing={4} w="100%" align="left">
        {getAddressForm("Name", "name")}
        {getAddressForm("Street 1", "address_line1")}
        {getAddressForm("Street 2", "address_line2")}
        {getAddressForm("Street 3", "address_line3")}
        {getAddressForm("City", "city")}
        {getAddressForm("State Region", "state")}
        {getAddressForm("Country", "country")}
        {getAddressForm("ZIP Code", "zip_code")}
      </VStack>
    </HStack>
  );
}

function AddressDetails(addressData) {
  const getAddressDetail = (label, value) => {
    return (
      <Grid templateColumns="repeat(11, 1fr)" gap={1}>
        <GridItem colSpan={3}>
          <FormLabel>{label}</FormLabel>
        </GridItem>
        <GridItem colSpan={8}>
          <Input
            type="text"
            value={value || ""}
            isReadOnly
            _hover={{ background: "none" }}
            _focus={{ outline: "none" }}
          />
        </GridItem>
      </Grid>
    );
  };

  return (
    <HStack w="100%" m={1} align="left">
      <VStack spacing={4} w="100%" align="left">
        {getAddressDetail("Name", addressData?.name)}
        {getAddressDetail("Street 1", addressData?.address_line1)}
        {getAddressDetail("Street 2", addressData?.address_line2)}
        {getAddressDetail("Street 3", addressData?.address_line3)}
        {getAddressDetail("City", addressData?.city)}
        {getAddressDetail("State Region", addressData?.state)}
        {getAddressDetail("Country", addressData?.country)}
        {getAddressDetail("ZIP Code", addressData?.zip_code)}
      </VStack>
    </HStack>
  );
}

function CreateModalAddress() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const ModalAddress = ({ addressData, acceptLabel, onClickAccept }) => {
    return (
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              name: addressData?.name || "",
              address_line1: addressData?.address_line1 || "",
              address_line2: addressData?.address_line2 || "",
              address_line3: addressData?.address_line3 || "",
              city: addressData?.city || "",
              state: addressData?.state || "",
              country: addressData?.country || "",
              zip_code: addressData?.zip_code || "",
            }}
            onSubmit={(values) => {
              onClickAccept(values);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ModalHeader>Address Information</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <AddressForm />
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="red" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="blue" onClick={handleSubmit}>
                    {acceptLabel}
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    );
  };

  return { openModal: onOpen, closeModal: onClose, ModalAddress };
}

function GetAddressSummary(addressData) {
  return (
    (addressData?.address_line1 || "") +
    " " +
    (addressData?.address_line2 || "") +
    " " +
    (addressData?.address_line3 || "") +
    " " +
    (addressData?.city || "") +
    " " +
    (addressData?.state || "") +
    " " +
    (addressData?.country || "")
  );
}
