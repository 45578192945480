import { useState } from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Stack,
  StackDivider,
  VStack,
  HStack,
  Spacer,
  Center,
} from "@chakra-ui/react";
import { UserAPI } from "../../api/UserAPI";
import PasswordInput from "../utils/PasswordInput";

const PasswordChangeForm = () => {
  const [submittingData, setSubmittingData] = useState(false);
  const [submitPassword] = UserAPI.updateUserMe();

  return (
    <VStack spacing={4} padding={4}>
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"left"}>
          Change your password
        </Heading>
      </Stack>
      <Box bg="white">
        <HStack mb={4} w="100%">
          <Spacer />
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
          <Spacer />
        </HStack>
        <Formik
          initialValues={{
            current_password: "",
            new_password: "",
            password_confirmation: "",
          }}
          onSubmit={async (values, { resetForm, setFieldError }) => {
            if (values["password_confirmation"] != values["new_password"]) {
              setFieldError("password_confirmation", "Passwords don't match");
              setSubmittingData(false);
              return;
            }
            submitPassword({
              current_password: values["current_password"],
              user_in: { password: values["new_password"] },
            }).then((response) => {
              setSubmittingData(false);
              if (response?.status == 200) {
                resetForm();
              }
            });
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isInvalid={
                    errors.current_password && touched.current_password
                  }
                >
                  <FormLabel htmlFor="name">Current password</FormLabel>
                  <Field
                    as={PasswordInput}
                    id="current_password"
                    name="current_password"
                    variant="filled"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Current password is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.current_password}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.new_password && touched.new_password}
                >
                  <FormLabel htmlFor="name">New password</FormLabel>
                  <Field
                    as={PasswordInput}
                    id="new_password"
                    name="new_password"
                    variant="filled"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "New password is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.new_password}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    errors.password_confirmation &&
                    touched.password_confirmation
                  }
                >
                  <FormLabel htmlFor="password_confirmation">
                    Confirm new password
                  </FormLabel>
                  <Field
                    as={PasswordInput}
                    id="password_confirmation"
                    name="password_confirmation"
                    variant="filled"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Password confirmation is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>
                    {errors.password_confirmation}
                  </FormErrorMessage>
                </FormControl>
                <Center w="100%">
                  <Button
                    isLoading={submittingData}
                    loadingText="Submitting"
                    type="submit"
                    colorScheme="purple"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      submittingData
                    }
                    onClick={(value) => {
                      handleSubmit(value);
                      setSubmittingData(true);
                    }}
                  >
                    Change password
                  </Button>
                </Center>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default PasswordChangeForm;
