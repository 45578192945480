import React from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  useColorModeValue,
  VStack,
  Center,
} from "@chakra-ui/react";
import { LoginAPI } from "../../api/LoginAPI";
import PasswordInput from "../utils/PasswordInput";
import { DarkHomeLogo } from "../utils/HomeLogo";

const Login = () => {
  const [submittingData, setSubmittingData] = React.useState(false);
  const submitLogin = LoginAPI.postLogin();

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bgGradient="radial(white, blue.50)"
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <DarkHomeLogo size="60px" />
          <Text fontSize={"lg"} color={"gray.600"}>
            Sign in to to enjoy all of our features
          </Text>
        </Stack>

        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Formik
            initialValues={{
              name: "",
              password: "",
            }}
            onSubmit={async (values) => {
              submitLogin(values).then(() => {
                setSubmittingData(false);
              });
            }}
          >
            {({ handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <VStack spacing={4} align="flex-start">
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel htmlFor="name">User name</FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name="name"
                      type="name"
                      variant="filled"
                      validate={(value) => {
                        let error;
                        if (!value) {
                          error = "Name is required";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.password && touched.password}>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Field
                      as={PasswordInput}
                      id="password"
                      name="password"
                      variant="filled"
                      validate={(value) => {
                        let error;
                        if (!value) {
                          error = "Password is required";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>
                  <Center w="100%" p={3}>
                    <Button
                      isLoading={submittingData}
                      loadingText="Logging in"
                      type="submit"
                      colorScheme="purple"
                      disabled={
                        Object.keys(errors).length > 0 ||
                        Object.keys(touched).length === 0 ||
                        submittingData
                      }
                      onClick={(value) => {
                        handleSubmit(value);
                        setSubmittingData(true);
                      }}
                    >
                      Login
                    </Button>
                  </Center>
                </VStack>
              </form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
